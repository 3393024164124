<template>
  <div
    class="my-4"
  >
    <h3>{{title}}</h3>
    <div class="dropdown-divider my-2" />
    <div class="attributes-container lined thick inline-block" style="font-size: 16px">
      <div>
        <div class="row align-items-center" style="font-weight: 500">
          <div class="col-md-1">No</div>
          <div class="col-md-3">Name</div>
          <div class="col-md-1">Count</div>          
          <div class="col-md-1">Avg</div>
          <div class="col-md-1">Low</div>
          <div class="col-md-5">Masks</div>
        </div>
      </div>
      <div v-for="(item, index) in items" :key="item.name">
        <hr class="my-3"/>
          <div class="row align-items-center">
            <div class="col-md-1">#{{ index + 1 }}</div>
            <div class="col-md-3">
              <div class="name-column">{{ item.name }}</div>
            </div>
            <div class="col-md-1">
              <div>{{ item.count || 0 }}</div>
            </div>
            <div class="col-md-1">
              <div>{{ item.avg_price || 50 }}</div>
            </div>
            <div class="col-md-1">
              <div>{{ item.low_price || 10 }}</div>
            </div>
            <div class="col-md-5 d-flex">
              <div
                v-for="thumbnailUrl in item.thumbnailUrls.slice(0, 10)"
                :key="thumbnailUrl"
              >
                <img v-bind:src="thumbnailUrl" height="60" />
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttributeItem',
  props: {
    items: { required: true, type: Array },
    title: { required: true, type: String, default: ""}
  }
};
</script>

<style></style>
