<template>
  <div id="content-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div
            class="project-page mt-4 d-flex justify-content-between align-items-center"
            style="text-align: left"
          >
            <h2 class="m-0">Attributes</h2>           
          </div>
          <div v-if="loading === true">
            <div class="progress-bar-outer lined thick mx-0">
              <div
                class="progress-bar-inner"
                v-bind:style="{ width: progress * 100 + '%' }"
              ></div>
            </div>
          </div>
          <div v-else>
            <div class="project-desc d-flex justify-content-center">
              <div class="lined thick inline-block px-3">
                Sort
                <div>
                  <select v-model="sortMode">
                    <option :value="0">Rarity</option>
                    <option :value="1">Average</option>
                    <option :value="2">Lowest</option>
                  </select>
                </div>
              </div>
            </div>
            <attribute-item :items="baseCharacterTypes" title="Character" />
            <attribute-item :items="maskTypes" title="Mask" />
            <attribute-item :items="eyeTypes" title="Eye" />
            <attribute-item :items="skinTypes" title="Skin" />
            <attribute-item :items="backgroundTypes" title="Background" />
            <attribute-item :items="glyphTypes" title="Glyph" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script defer>
import * as Comlink from 'comlink';
import { loadHashMaskNames, getMaskAttributes } from '../helpers/database.worker';
import AttributeItem from '../components/AttributeItem';

export default {
  name: 'Attributes',
  components: { AttributeItem },
  data() {
    return {
      loading: true,
      progress: 0,
      sortMode: "0",
      skinTypes: [],
      baseCharacterTypes: [],
      eyeTypes: [],
      maskTypes: [],
      itemTypes: [],
      backgroundTypes: [],
      glyphTypes: [],
      setTypes: [],
    };
  },
  async mounted() {
    this.items = await loadHashMaskNames(
      Comlink.proxy(({ progress }) => {
        this.progress = progress;
      }),
    );
    await this.getMaskAttributes();
    this.onSortChange();
    this.loading = false;
  },
  watch: {
    'sortMode'() {
      this.onSortChange();
    },
  },
  methods: {
    async getMaskAttributes() {
      const response = await getMaskAttributes();
      this.skinTypes = response.skinTypes;
      this.baseCharacterTypes = response.baseCharacterTypes;
      this.eyeTypes = response.eyeTypes;
      this.maskTypes = response.maskTypes;
      this.itemTypes = response.itemTypes;
      this.backgroundTypes = response.backgroundTypes;
      this.glyphTypes = response.glyphTypes;
      this.setTypes = response.setTypes;
    },
    onSortChange() {
      let sortMode = "count";
      switch (this.sortMode) {
        case 0: sortMode = "count"; break;
        case 1: sortMode = "avg_price"; break;
        case 2: sortMode = "low_price"; break;
      }
      this.skinTypes = this.skinTypes.sort((a, b) => a[sortMode] - b[sortMode])
      this.baseCharacterTypes = this.baseCharacterTypes.sort((a, b) => a[sortMode] - b[sortMode])
      this.eyeTypes = this.eyeTypes.sort((a, b) => a[sortMode] - b[sortMode])
      this.maskTypes = this.maskTypes.sort((a, b) => a[sortMode] - b[sortMode])
      this.itemTypes = this.itemTypes.sort((a, b) => a[sortMode] - b[sortMode])
      this.backgroundTypes = this.backgroundTypes.sort((a, b) => a[sortMode] - b[sortMode])
      this.glyphTypes = this.glyphTypes.sort((a, b) => a[sortMode] - b[sortMode])
      this.setTypes = this.setTypes.sort((a, b) => a[sortMode] - b[sortMode])
    },
  },
};
</script>

<style></style>
